import { WP_API_BASE } from '../../util/constants'
// import * as _ from 'lodash'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  application: false,
  data: [],
  filters: {},
  activeFilters: {
    productId: false,
    dhsv_download_category: false,
    dhsv_download_type: false,
    dhsv_download_lang: false,
  },
  states: {
    postsShowed: 0,
    postsLoaded: 0,
    perPageLoad: 6,
    allPostsLoaded: false,
  },
}

const LOAD_DOWNLOADS_DATA = 'LOAD_DOWNLOADS_DATA'
const LOAD_DOWNLOADS_DATA_SUCCESS = 'LOAD_DOWNLOADS_DATA_SUCCESS'
const LOAD_DOWNLOADS_DATA_FAILURE = 'LOAD_DOWNLOADS_DATA_FAILURE'
const SET_DOWNLOADS_STATES = 'SET_DOWNLOADS_STATES'
const SET_DOWNLOADS_ACTIVE_FILTERS = 'SET_DOWNLOADS_ACTIVE_FILTERS'
const SET_APPLICATION_FLAG = 'SET_APPLICATION_FLAG'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_DOWNLOADS_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_DOWNLOADS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: [...payload.data],
        filters: payload.filters
          ? { ...payload.filters }
          : { ...state.filters },
      }

    case LOAD_DOWNLOADS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case SET_DOWNLOADS_STATES:
      return {
        ...state,
        states: { ...payload },
      }

    case SET_DOWNLOADS_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: { ...payload },
      }

    case SET_APPLICATION_FLAG:
      return {
        ...state,
        application: payload,
      }

    default:
      return state
  }
}

export const loadData = (firstRequest, filters = undefined) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_DOWNLOADS_DATA })

  let currentPosts = [...getState().downloads.data]
  if (currentPosts === undefined) {
    currentPosts = new Array()
  }

  // Rest API Params
  const params = {
    firstRequest: firstRequest,
    onlineAntrage: getState().downloads.application,
  }

  const format = window.siteLang.format
  let apiBaseUrl = WP_API_BASE
  if (parseInt(format) === 3) {
    params.lang = window.siteLang.current
  } else if (window.siteLang.current !== window.siteLang.default) {
    apiBaseUrl = '/' + window.siteLang.current + apiBaseUrl
  }

  if (filters !== undefined) {
    Object.keys(filters).map(key => {
      if (filters[key]) {
        params[key] = filters[key]
      }
    })
  }

  let url_params = '/'
  if (params) {
    let i = 0
    for (let key in params) {
      params.hasOwnProperty(key)
        ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
        : false
      i++
    }
  }
  return fetch(`${apiBaseUrl}downloads${url_params}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(response => {
      let payload = {
        data: [...response.data],
        filters: response.filters && { ...response.filters },
      }

      dispatch({
        payload,
        type: LOAD_DOWNLOADS_DATA_SUCCESS,
      })

      if (filters !== undefined) {
        const postsLength = response.data.length
        dispatch({
          payload: {
            postsLoaded: postsLength,
            postsShowed: postsLength >= 7 ? 7 : postsLength,
            allPostsLoaded: postsLength <= 7 ? true : false,
            perPageLoad: 6,
          },
          type: SET_DOWNLOADS_STATES,
        })

        dispatch({
          payload: filters,
          type: SET_DOWNLOADS_ACTIVE_FILTERS,
        })
      }

      return response
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_DOWNLOADS_DATA_FAILURE,
      })
    })
}

export const changeStates = states => dispatch => {
  return dispatch({
    type: SET_DOWNLOADS_STATES,
    payload: states,
  })
}

export const changeApplication = change => dispatch => {
  return dispatch({
    payload: change,
    type: SET_APPLICATION_FLAG,
  })
}
