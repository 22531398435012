import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import downloads from './downloads'
import news from './news'

const appReducer = combineReducers({
  browser,
  location: router,
  downloads,
  news,
})

export default appReducer
