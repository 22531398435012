import stickElement from '../../scripts/util/stickit'

var $pageAnchors = $('.page-anchors')
var handleAnchorSwitch = $anchor => {
  $pageAnchors.find('.js-current-amchor').text($anchor.text())
}

$('body').scrollspy({
  target: '#page-anchors',
  offset: 120,
})

$(window)
  .on('hashchange', function() {
    var hash = location.hash
    $pageAnchors.find('a').each(function() {
      var that = $(this)
      that[that.attr('href') === hash ? 'addClass' : 'removeClass']('active')
    })
  })
  .on('activate.bs.scrollspy', function(e, obj) {
    handleAnchorSwitch($('a[href="' + obj.relatedTarget + '"]'))
  })

$('.js-mobile-anchor-toggle').on('click', function() {
  $(this)
    .closest('.page-anchors')
    .find('.page-anchors__list')
    .slideToggle()
})

stickElement('.page-anchors')
